import { NgForm } from '@angular/forms';
import { Selection, ObjectAddress } from './../selection/Selection';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectionService } from '../selection/selection.service';
import { NgxLoadingConfig, ngxLoadingAnimationTypes } from 'ngx-loading';

@Component({
  selector: 'app-contact-information',
  templateUrl: './contact-information.component.html'
})
export class ContactInformationComponent implements OnInit, OnDestroy {

  private ngUnsubscribe: Subject<any> = new Subject();

  type:string;

  selectionDataForType:Selection[];
  objectAddress:ObjectAddress;

  // ngx-loading
  showLoadingSpinner:boolean = false;
  loadingSpinnerConfig:NgxLoadingConfig = {
    animationType: ngxLoadingAnimationTypes.circleSwish,
    backdropBackgroundColour: 'rgba(0,0,0,0.7)',
    backdropBorderRadius: '4px',
    primaryColour: '#ffffff',
    secondaryColour: '#ffffff',
    tertiaryColour: '#ffffff',
    fullScreenBackdrop: true
  }

  constructor(private route:ActivatedRoute, private router:Router, private selectionService:SelectionService) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if(params){
        const type = params['type']
        if(type == 'grundstueck' || type == 'haus' || type == 'wohnung' || type == 'gewerbe') {
          this.type = type;
        } else {
          this.router.navigate(['/']);
        }
      }
    })

    this.selectionDataForType = this.selectionService.selectionDataSnapshot[this.type];
    this.objectAddress = this.selectionService.objectAddressSnapshot;

    // if(!this.selectionService.isSelectionDataValid(this.type) || !this.selectionService.objectAddressSnapshot.address){
    //   this.router.navigate(['/']);
    // }
    
  }

  submitContactInformationForm(contactInformationForm:NgForm){
    if(!contactInformationForm.valid){
      return;
    }

    const value = contactInformationForm.value;
    this.selectionService.updateContactInformation(value);

    this.showLoadingSpinner = true;
    this.selectionService.addRequestToFirestore(this.type).then(() => {
      this.router.navigate([this.type, 'bestaetigung']);
      this.showLoadingSpinner = false;
    })
    .catch(err => {
      console.log(err);
      this.showLoadingSpinner = false;
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
