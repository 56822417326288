import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { environment } from './../environments/environment.prod';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';

import { Ng5SliderModule } from 'ng5-slider';
import { NgxLoadingModule } from 'ngx-loading';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SelectionComponent } from './selection/selection.component';
import { HomeComponent } from './home/home.component';
import { SelectionService } from './selection/selection.service';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { CheckMarkComponent } from './check-mark/check-mark.component';
import { ContactInformationComponent } from './contact-information/contact-information.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { AngularFireModule } from '@angular/fire';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faChevronRight, faChevronLeft, faAt, faPhone} from '@fortawesome/pro-light-svg-icons';

library.add(faChevronLeft, faChevronRight, faAt, faPhone);

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 1,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  pagination: {
    el: '.swiper-pagination',
    type: 'progressbar',
  },
  centeredSlides: true,
  noSwipingSelector: 'ng5-slider',
  loop: false,
  keyboard: {
    enabled: true,
    onlyInViewport: false,
  },
  shortSwipes: false,
  longSwipesRatio: 0.1
};

@NgModule({
  declarations: [
    AppComponent,
    SelectionComponent,
    HomeComponent,
    CheckMarkComponent,
    ContactInformationComponent,
    ConfirmationComponent,
    NavbarComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    FormsModule,
    Ng5SliderModule,
    FontAwesomeModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebase),
    SwiperModule,
    NgxLoadingModule.forRoot({})
  ],
  providers: [SelectionService, AngularFirestore, AngularFireAuth, {
    provide: SWIPER_CONFIG,
    useValue: DEFAULT_SWIPER_CONFIG
  }],
  bootstrap: [AppComponent]
})
export class AppModule {}
