import { ContactInformationComponent } from './contact-information/contact-information.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { SelectionComponent } from './selection/selection.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: ':type',
    component: SelectionComponent
  },
  {
    path: ':type/kontakt-informationen',
    component: ContactInformationComponent
  },
  {
    path: ':type/bestaetigung',
    component: ConfirmationComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
