import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { Injectable } from '@angular/core';
import { SelectionData, ObjectAddress, ContactInformation } from './Selection';
import { LabelType } from 'ng5-slider';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SelectionService {

  private selectionDataSubject:BehaviorSubject<SelectionData>;
  private objectAddressSubject:BehaviorSubject<ObjectAddress> = new BehaviorSubject(null);
  private contactInformationSubject:BehaviorSubject<ContactInformation> = new BehaviorSubject(null);
  private objectNotesSubject:BehaviorSubject<string> = new BehaviorSubject("");

  private sliderOptionsTranslateArea = (value: number, label: LabelType): string => {
    switch (label) {
      case LabelType.Floor:
        return "< " + value + " m&#178;";
      case LabelType.Ceil:
        return "> " + value + " m&#178;";
      default:
        return '~' + value + " m&#178;";
    }
  }

  private sliderOptionsTranslateYear = (value: number, label: LabelType): string => {
    switch (label) {
      case LabelType.Floor:
        return "< " + value;
      case LabelType.Ceil:
        return value.toString();
      default:
        return '~' + value;
    }
  }

  private sliderOptionsTranslateRoom = (value: number, label: LabelType): string => {
    switch (label) {
      case LabelType.Floor:
        return "< " + value;
      case LabelType.Ceil:
        return "> " + value.toString();
      default:
        return value + " Zimmer";
    }
  }

  private sliderOptionsTranslateParking = (value: number, label: LabelType): string => {
    switch (label) {
      case LabelType.Floor:
        return "< " + value;
      case LabelType.Ceil:
        return "> " + value.toString();
      default:
        return '~' + value + " Parkplätze";
    }
  }

  private sliderOptionsTranslateBed = (value: number, label: LabelType): string => {
    switch (label) {
      case LabelType.Floor:
        return "< " + value;
      case LabelType.Ceil:
        return "> " + value.toString();
      default:
        return '~' + value + " Betten";
    }
  }

  constructor(private db:AngularFirestore, private auth:AngularFireAuth) {
    this.initializeSelectionData();
  }

  updateSelectionData(data:SelectionData){
    this.selectionDataSubject.next(data);
  }

  getSelectionData(){
    return this.selectionDataSubject.asObservable();
  }

  updateObjectAddress(data:ObjectAddress){
    this.objectAddressSubject.next(data);
  }

  getObjectAddress(){
    return this.objectAddressSubject.asObservable();
  }

  updateContactInformation(data:ContactInformation){
    this.contactInformationSubject.next(data);
  }

  getContactInformation(){
    return this.contactInformationSubject.asObservable();
  }

  updateObjectNotes(data:string){
    this.objectNotesSubject.next(data);
  }

  getObjectNotes(){
    return this.objectNotesSubject.asObservable();
  }

  get selectionDataSnapshot(){
    return this.selectionDataSubject.getValue();
  }

  get objectAddressSnapshot(){
    return this.objectAddressSubject.getValue();
  }

  get contactInformationSnapshot(){
    return this.contactInformationSubject.getValue();
  }

  get objectNotesSnapshot(){
    return this.objectNotesSubject.getValue();
  }

  getTitleForType(type:string){
    if(type == 'grundstueck'){
      return 'Grundstück';
    } else if (type == 'haus'){
      return 'Haus';
    } else if (type == 'wohnung'){
      return 'Wohnung';
    } else {
      return 'Hotel/Gewerbe';
    }
  }

  isSelectionDataValid(type:string){
    for(let selection of this.selectionDataSnapshot[type]){
      if(!selection.isSlider && !selection.selected){
        return false;
      }
    }
    return true;
  }
  
  addRequestToFirestore(type:string){
    let request = this.getRequestForType(type);

    return this.auth.auth.signInWithEmailAndPassword("admin@fortix.io", "WCGEd,!Eu1y*JpI]M*A:PPP-Y'%o&is3").then(authResult => {
      return this.db.collection('id').doc('id').ref.get().then(doc => {
        const currentId = doc.data().id;
        request['id'] = currentId + 1;
        return this.db.collection('requests').add(request).then(() => {
          // this.auth.auth.signOut();
          return this.db.collection('id').doc('id').update({id: currentId + 1});
        });
      })
    })
  }

  getRequestForType(type:string){
    let request = {};
    const selections = this.selectionDataSnapshot[type];
    request['type'] = type.charAt(0).toUpperCase() + type.slice(1); // first letter uppercase
    const selectionArray = [];
    for(let selection of selections){
      if (selection.onlyWhenSelected){
        if(this.selectionDataSnapshot[type][selection.onlyWhenSelected.index].selected.label != selection.onlyWhenSelected.label){
          continue;
        }
      }
      if(selection.isSlider){
        selectionArray.push({
          label: selection.title,
          value: selection.slider.value + selection.slider.entity
        })
      } else {
        selectionArray.push({
          label: selection.title,
          value: selection.selected.label
        })
      }
      request['selections'] = selectionArray;
    }
    request['objectNotes'] = this.objectNotesSnapshot;
    request['objectAddress'] = this.objectAddressSnapshot;
    request['contactInformation'] = this.contactInformationSnapshot;

    return request;
  }

  getValidZipCodes() {
    return [
      "6000",
      "6010",
      "6013",
      "6018",
      "6019",
      "6020",
      "6021",
      "6022",
      "6023",
      "6029",
      "6033",
      "6034",
      "6050",
      "6060",
      "6063",
      "6065",
      "6067",
      "6068",
      "6069",
      "6070",
      "6071",
      "6072",
      "6073",
      "6074",
      "6075",
      "6080",
      "6082",
      "6083",
      "6091",
      "6092",
      "6094",
      "6095",
      "6100",
      "6103",
      "6105",
      "6108",
      "6111",
      "6112",
      "6113",
      "6114",
      "6115",
      "6116",
      "6121",
      "6122",
      "6401",
      "6402",
      "6403",
      "6404",
      "6405",
      "6406",
      "6408",
      "6410",
      "6412",
      "6413",
      "6414",
      "6416",
      "6421",
      "6422",
      "6423",
      "6424",
      "6425",
      "6426",
      "6430",
      "6432",
      "6433",
      "6441",
      "6444",
      "6450",
      "6452",
      "6456",
      "6458",
      "6460",
      "6462",
      "6463",
      "6464",
      "6465",
      "6471",
      "6473",
      "6474",
      "6481",
      "6491",
      "6492",
      "6493",
      "6500",
      "6511",
      "6521",
      "6522",
      "6524",
      "6525",
      "6526",
      "6527",
      "6528",
      "6531",
      "6532",
      "6533",
      "6534",
      "6541",
      "6542",
      "6543",
      "6544",
      "6551",
      "6552",
      "6553",
      "6555",
      "6561",
      "6562",
      "6563",
      "6571",
      "6572",
      "6574",
      "6580",
      "6591",
      "6600",
      "6604",
      "6610",
      "6611",
      "6621",
      "6622",
      "6623",
      "6631",
      "6632",
      "6633",
      "6642",
      "6644",
      "6645",
      "6646",
      "6647",
      "6650",
      "6651",
      "6652",
      "6653",
      "6654",
      "6655",
      "6670",
      "6671",
      "6672",
      "6673",
      "6675",
      "6677",
      "6682",
      "6691"
    ];
  }

  initializeSelectionData(){
    this.selectionDataSubject = new BehaviorSubject({
      grundstueck: [
        {
          isSlider: true,
          title: 'Grundstückfläche',
          subtitle: 'Welche Fläche hat das Grundstück?',
          variableName: 'landArea',
          slider: {
            icon: {
              assetUrl: "url('./assets/icons/grundstueck_flaeche.svg')",
              backgroundWidth: '70%'
            },
            value: 500,
            options: {
              floor: 100,
              ceil: 2000,
              step: 10,
              translate: this.sliderOptionsTranslateArea,
              showSelectionBar: true
            },
            entity: 'm2'
          }
        },
        {
          isSlider:false,
          title: 'Erschließung',
          subtitle: 'Ist das Grundstück erschlossen?',
          variableName: 'accessed',
          items: [
            {
              icon: {
                assetUrl: "url('./assets/icons/erschlossen.svg')",
                backgroundWidth: '90%'
              },
              label: 'Erschlossen'
            },
            {
              icon: {
                assetUrl: "url('./assets/icons/teilerschlossen.svg')",
                backgroundWidth: '90%'
              },
              label: 'Teilerschlossen'
            },
            {
              icon: {
                assetUrl: "url('./assets/icons/nicht_erschlossen.svg')",
                backgroundWidth: '90%'
              },
              label: 'Nicht erschlossen'
            }
          ]
        },
        {
          isSlider:false,
          title: 'Flächenwidmung',
          subtitle: 'Ist das Grundstück bebaubar?',
          variableName: 'buildable',
          items: [
            {
              icon: {
                assetUrl: "url('./assets/icons/bauland.svg')",
                backgroundWidth: '70%'
              },
              label: 'Bauland'
            },
            {
              icon: {
                assetUrl: "url('./assets/icons/freiland.svg')",
                backgroundWidth: '70%'
              },
              label: 'Freiland'
            }
          ]
        },
        {
          isSlider:false,
          title: 'Grundstücksart',
          subtitle: 'Was ist die Art des Grundstückes?',
          variableName: 'propertyType',
          items: [
            {
              icon: {
                assetUrl: "url('./assets/icons/ebenes_grundstueck.svg')",
                backgroundWidth: '70%'
              },
              label: 'Ebenes Grundstück'
            },
            {
              icon: {
                assetUrl: "url('./assets/icons/hanglage.svg')",
                backgroundWidth: '60%'
              },
              label: 'Hanglage'
            }
          ]
        }
      ],
      haus: [
        {
          isSlider:false,
          title: 'Art des Hauses',
          subtitle: 'Bitte wählen Sie die Art Ihres Hauses',
          variableName: 'houseType',
          items: [
            {
              icon: {
                assetUrl: "url('./assets/icons/einfamilienhaus2.svg')",
                backgroundWidth: '60%'
              },
              label: 'Einfamilienhaus'
            },
            {
              icon: {
                assetUrl: "url('./assets/icons/mehrfamilienhaus2.svg')",
                backgroundWidth: '75%'
              },
              label: 'Mehrfamilienhaus'
            },
            {
              icon: {
                assetUrl: "url('./assets/icons/reihenhaus2.svg')",
                backgroundWidth: '75%'
              },
              label: 'Reihenhaus'
            },
            {
              icon: {
                assetUrl: "url('./assets/icons/doppelhaushaelfte2.svg')",
                backgroundWidth: '80%'
              },
              label: 'Doppelhaushälfte'
            }
          ]
        },
        {
          isSlider:false,
          title: 'Etagen',
          subtitle: 'Wie viele Etagen hat das Haus?',
          variableName: 'floorsCount',
          items: [
            {
              icon: {
                assetUrl: "url('./assets/icons/einfamilienhaus2.svg')",
                backgroundWidth: '65%'
              },
              label: 'Eine Etage'
            },
            {
              icon: {
                assetUrl: "url('./assets/icons/zwei_etagen.svg')",
                backgroundWidth: '70%'
              },
              label: 'Zwei Etagen'
            },
            {
              icon: {
                assetUrl: "url('./assets/icons/drei_etagen.svg')",
                backgroundWidth: '70%'
              },
              label: '> Zwei Etagen'
            }
          ]
        },
        {
          isSlider: true,
          title: 'Wohnfläche',
          subtitle: 'Bitte geben Sie die gesamte Wohnfläche an',
          variableName: 'livingArea',
          slider: {
            icon: {
              assetUrl: "url('./assets/icons/wohnflaeche.svg')",
              backgroundWidth: '50%'
            },
            value: 200,
            options: {
              floor: 70,
              ceil: 500,
              step: 10,
              translate: this.sliderOptionsTranslateArea,
              showSelectionBar: true
            },
            entity: 'm2'
          }
        },
        {
          isSlider: true,
          title: 'Grundstückfläche',
          subtitle: 'Welche Fläche hat das Grundstück?',
          variableName: 'landArea',
          slider: {
            icon: {
              assetUrl: "url('./assets/icons/grundstueck_flaeche.svg')",
              backgroundWidth: '70%'
            },
            value: 500,
            options: {
              floor: 100,
              ceil: 2000,
              step: 10,
              translate: this.sliderOptionsTranslateArea,
              showSelectionBar: true
            },
            entity: 'm2'
          }
        },
        {
          isSlider: true,
          title: 'Baujahr',
          subtitle: 'In welchem Jahr wurde das Haus gebaut?',
          variableName: 'buildYear',
          slider: {
            icon: {
              assetUrl: "url('./assets/icons/baujahr.svg')",
              backgroundWidth: '90%'
            },
            value: 1980,
            options: {
              floor: 1900,
              ceil: new Date().getFullYear(),
              step: 1,
              translate: this.sliderOptionsTranslateYear,
              showSelectionBar: true
            },
            entity: ''
          }
        },
        {
          isSlider:false,
          title: 'Zustand des Hauses',
          subtitle: 'Wie ist der Zustand des Hauses?',
          variableName: 'houseCondition',
          items: [
            {
              icon: {
                assetUrl: "url('./assets/icons/saniert.svg')",
                backgroundWidth: '65%'
              },
              label: 'Saniert'
            },
            {
              icon: {
                assetUrl: "url('./assets/icons/teilsaniert.svg')",
                backgroundWidth: '65%'
              },
              label: 'Teilsaniert'
            },
            {
              icon: {
                assetUrl: "url('./assets/icons/unsaniert.svg')",
                backgroundWidth: '65%'
              },
              label: 'Unsaniert'
            },
            {
              icon: {
                assetUrl: "url('./assets/icons/question.svg')",
                backgroundWidth: '60%'
              },
              label: 'Weiß nicht'
            }
          ]
        }
      ],
      wohnung: [
        {
          isSlider: true,
          title: 'Wohnfläche',
          subtitle: 'Bitte geben Sie die gesamte Wohnfläche an',
          variableName: 'livingArea',
          slider: {
            icon: {
              assetUrl: "url('./assets/icons/wohnflaeche.svg')",
              backgroundWidth: '50%'
            },
            value: 100,
            options: {
              floor: 50,
              ceil: 250,
              step: 5,
              translate: this.sliderOptionsTranslateArea,
              showSelectionBar: true
            },
            entity: 'm2'
          }
        },
        {
          isSlider: true,
          title: 'Zimmer',
          subtitle: 'Wie viel Zimmer hat die Wohnung? (ohne Küche und Bad)',
          variableName: 'roomCount',
          slider: {
            icon: {
              assetUrl: "url('./assets/icons/rooms.svg')",
              backgroundWidth: '50%'
            },
            value: 4,
            options: {
              floor: 1,
              ceil: 10,
              step: 1,
              translate: this.sliderOptionsTranslateRoom,
              showSelectionBar: true
            },
            entity: ' Zimmer'
          }
        },
        {
          isSlider: true,
          title: 'Baujahr',
          subtitle: 'In welchem Jahr wurde das Haus gebaut?',
          variableName: 'buildYear',
          slider: {
            icon: {
              assetUrl: "url('./assets/icons/baujahr.svg')",
              backgroundWidth: '60%'
            },
            value: 1980,
            options: {
              floor: 1900,
              ceil: new Date().getFullYear(),
              step: 1,
              translate: this.sliderOptionsTranslateYear,
              showSelectionBar: true
            },
            entity: ''
          }
        },
        {
          isSlider:false,
          title: 'Parkmöglichkeiten',
          subtitle: 'Welche Parkmöglichkeit hat die Wohnung inkludiert?',
          variableName: 'parking',
          items: [
            {
              icon: {
                assetUrl: "url('./assets/icons/garage.svg')",
                backgroundWidth: '70%'
              },
              label: 'Garage'
            },
            {
              icon: {
                assetUrl: "url('./assets/icons/carport.svg')",
                backgroundWidth: '70%'
              },
              label: 'Carport'
            },
            {
              icon: {
                assetUrl: "url('./assets/icons/parken_freien.svg')",
                backgroundWidth: '60%'
              },
              label: 'Stellplatz im Freien'
            },
            {
              icon: {
                assetUrl: "url('./assets/icons/kein_parkplatz.svg')",
                backgroundWidth: '50%'
              },
              label: 'Keine'
            }
          ]
        },
        {
          isSlider:false,
          title: 'Aufzug',
          subtitle: 'Hat das Wohngebäude einen Aufzug?',
          variableName: 'elevator',
          items: [
            {
              icon: {
                assetUrl: "url('./assets/icons/aufzug.svg')",
                backgroundWidth: '60%'
              },
              label: 'Aufzug'
            },
            {
              icon: {
                assetUrl: "url('./assets/icons/kein_aufzug.svg')",
                backgroundWidth: '60%'
              },
              label: 'Kein Aufzug'
            }
          ]
        }
      ],
      gewerbe: [
        {
          isSlider:false,
          title: 'Gebäudeart',
          subtitle: 'Unter welche Gebäudeart fällt Ihre Immobilie?',
          variableName: 'houseType',
          items: [
            {
              icon: {
                assetUrl: "url('./assets/icons/hotel.svg')",
                backgroundWidth: '70%'
              },
              label: 'Hotel/Pension'
            },
            {
              icon: {
                assetUrl: "url('./assets/icons/bueros.svg')",
                backgroundWidth: '75%'
              },
              label: 'Bürogebäude'
            },
            {
              icon: {
                assetUrl: "url('./assets/icons/industrie.svg')",
                backgroundWidth: '75%'
              },
              label: 'Industriegebäude'
            },
            {
              icon: {
                assetUrl: "url('./assets/icons/question.svg')",
                backgroundWidth: '55%'
              },
              label: 'Sonstiges'
            }
          ]
        },
        {
          isSlider: true,
          title: 'Grundstückfläche',
          subtitle: 'Welche Fläche hat das Grundstück?',
          variableName: 'landArea',
          slider: {
            icon: {
              assetUrl: "url('./assets/icons/grundstueck_flaeche.svg')",
              backgroundWidth: '70%'
            },
            value: 500,
            options: {
              floor: 100,
              ceil: 2000,
              step: 10,
              translate: this.sliderOptionsTranslateArea,
              showSelectionBar: true
            },
            entity: 'm2'
          }
        },
        {
          isSlider: true,
          title: 'Nutzfläche',
          subtitle: 'Bitte geben Sie die gesamte Nutzfläche an',
          variableName: 'livingArea',
          slider: {
            icon: {
              assetUrl: "url('./assets/icons/wohnflaeche.svg')",
              backgroundWidth: '50%'
            },
            value: 500,
            options: {
              floor: 100,
              ceil: 2000,
              step: 10,
              translate: this.sliderOptionsTranslateArea,
              showSelectionBar: true
            },
            entity: 'm2'
          }
        },
        {
          isSlider: false,
          title: 'Zimmer/Ferienwohnungen',
          subtitle: 'Hat das Hotel überwiegend Zimmer oder Ferienwohnungen?',
          variableName: 'hotelRooms',
          items: [
            {
              icon: {
                assetUrl: "url('./assets/icons/hotelzimmer.svg')",
                backgroundWidth: '75%'
              },
              label: 'Überwiegend Zimmer'
            },
            {
              icon: {
                assetUrl: "url('./assets/icons/ferienwohnung.svg')",
                backgroundWidth: '90%'
              },
              label: 'Überwiegend Ferienwohnungen'
            }
          ],
          onlyWhenSelected: {index: 0, label: 'Hotel/Pension'}
        },
        {
          isSlider: true,
          title: 'Betten',
          subtitle: 'Wie viele Betten hat das Gebäude?',
          variableName: 'hotelRooms',
          slider: {
            icon: {
              assetUrl: "url('./assets/icons/betten.svg')",
              backgroundWidth: '50%'
            },
            value: 10,
            options: {
              floor: 2,
              ceil: 60,
              step: 1,
              translate: this.sliderOptionsTranslateBed,
              showSelectionBar: true
            },
            entity: ' Betten'
          },
          onlyWhenSelected: {index: 0, label: 'Hotel/Pension'}
        },
        {
          isSlider:false,
          title: 'Spa/Wellness',
          subtitle: 'Hat das Hotel ein Spa-/Wellnessbereich?',
          variableName: 'wellness',
          items: [
            {
              icon: {
                assetUrl: "url('./assets/icons/wellness.svg')",
                backgroundWidth: '60%'
              },
              label: 'Spa/Wellness'
            },
            {
              icon: {
                assetUrl: "url('./assets/icons/kein_wellness.svg')",
                backgroundWidth: '60%'
              },
              label: 'Kein Spa/Wellness'
            }
          ],
          onlyWhenSelected: {index: 0, label: 'Hotel/Pension'}
        },
        {
          isSlider: true,
          title: 'Baujahr',
          subtitle: 'In welchem Jahr wurde das Gebäude gebaut?',
          variableName: 'buildYear',
          slider: {
            icon: {
              assetUrl: "url('./assets/icons/baujahr.svg')",
              backgroundWidth: '90%'
            },
            value: 1980,
            options: {
              floor: 1900,
              ceil: new Date().getFullYear(),
              step: 1,
              translate: this.sliderOptionsTranslateYear,
              showSelectionBar: true
            },
            entity: ''
          }
        },
        {
          isSlider: true,
          title: 'Parkplätze',
          subtitle: 'Wie viele Parkplätze bietet das Gebäude?',
          variableName: 'parkingSpots',
          slider: {
            icon: {
              assetUrl: "url('./assets/icons/garage.svg')",
              backgroundWidth: '50%'
            },
            value: 10,
            options: {
              floor: 3,
              ceil: 30,
              step: 1,
              translate: this.sliderOptionsTranslateParking,
              showSelectionBar: true
            },
            entity: ' Parkplätze'
          }
        }
      ]
    })

    const snapshot = this.selectionDataSnapshot;

    for(let key of Object.keys(snapshot)){
      snapshot[key].push({
        isSlider:false,
        title: 'Verkaufszeitpunkt',
        subtitle: 'Wann wären Sie interessiert zu verkaufen?',
        variableName: 'sellingTime',
        items: [
          {
            icon: {
              assetUrl: "url('./assets/icons/1-3monate.svg')",
              backgroundWidth: '60%'
            },
            label: '1 - 3 Monate'
          },
          {
            icon: {
              assetUrl: "url('./assets/icons/4-6monate.svg')",
              backgroundWidth: '60%'
            },
            label: '4 - 6 Monate'
          },
          {
            icon: {
              assetUrl: "url('./assets/icons/groesser6monate.svg')",
              backgroundWidth: '60%'
            },
            label: 'später als 6 Monate'
          },
          {
            icon: {
              assetUrl: "url('./assets/icons/kein_verkauf.svg')",
              backgroundWidth: '60%'
            },
            label: 'im Moment nicht'
          }
        ]
      })
      
      this.updateSelectionData(snapshot);
    }
  }
}
