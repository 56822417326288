import { SwiperConfigInterface, SwiperComponent } from 'ngx-swiper-wrapper';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Observable } from 'rxjs';
import { SelectionData, Item, Selection } from './Selection';
import { ChangeDetectorRef } from '@angular/core';
import { LabelType } from 'ng5-slider';
import { SelectionService } from './selection.service';
import { SwiperDirective } from 'ngx-swiper-wrapper';
import { NgForm, NgModel } from '@angular/forms';
import { NgxLoadingConfig, ngxLoadingAnimationTypes } from 'ngx-loading';

@Component({
  selector: 'app-selection',
  templateUrl: './selection.component.html'
})
export class SelectionComponent implements OnInit, OnDestroy {

  private ngUnsubscribe: Subject<any> = new Subject();

  type: string;

  data: SelectionData;
  hotelDataSource: Selection[];
  notHotelDataSource: Selection[];

  objectNotes: string = "";

  index: number = 0;

  config: SwiperConfigInterface = {
    allowSlideNext: false,
    shortSwipes: 'ontouchstart' in window || !!navigator.msMaxTouchPoints,
    navigation: {
      nextEl: '.swiper-button-next-custom',
      prevEl: '.swiper-button-prev-custom'
    },
    autoHeight: true
  }

  allowNext: boolean = false;

  isHotelSelected: boolean = false;

  @ViewChild(SwiperDirective) directiveRef?: SwiperDirective;
  swiper: SwiperComponent;

  // ngx-loading
  showLoadingSpinner: boolean = false;
  loadingSpinnerConfig: NgxLoadingConfig = {
    animationType: ngxLoadingAnimationTypes.circleSwish,
    backdropBackgroundColour: 'rgba(0,0,0,0.7)',
    backdropBorderRadius: '4px',
    primaryColour: '#ffffff',
    secondaryColour: '#ffffff',
    tertiaryColour: '#ffffff',
    fullScreenBackdrop: true
  }

  @ViewChild('zip') zipNgModel: NgModel;
  isZipCodeValid = false;

  constructor(private route: ActivatedRoute, private router: Router, private _changeDetectionRef: ChangeDetectorRef, private selectionService: SelectionService) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params) {
        const type = params['type']
        if (type == 'grundstueck' || type == 'haus' || type == 'wohnung' || type == 'gewerbe') {
          this.type = type;
        } else {
          this.router.navigate(['/']);
        }
      }
    })

    this.selectionService.getSelectionData().subscribe(data => {
      this.data = data;
      // this.hotelDataSource = [...this.data['gewerbe']];
      // this.notHotelDataSource = [...this.data['gewerbe']];
      // this.notHotelDataSource.splice(3,3);
    });

    this.swiper = this.directiveRef.swiper();

    setTimeout(() => {
      this.updateAllowNext();
    }, 10);

    this.zipNgModel.valueChanges.subscribe(zip => {
      if (zip) {
        this.isZipCodeValid = !!this.selectionService.getValidZipCodes().find(validZipCode => zip === validZipCode);
      } else {
        this.isZipCodeValid = false;
      }
    })
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngAfterViewInit() {
    // Force another change detection in order to fix the dialog error
    this._changeDetectionRef.detectChanges();
  }

  selectItem(item: Item, selection: Selection) {
    selection.selected = item;
    this.directiveRef.swiper().allowSlideNext = true;
    this.allowNext = true;
    setTimeout(() => {
      this.directiveRef.swiper().slideNext();
      this.directiveRef.swiper().update();
    }, 500);

  }

  onSlideChange() {
    this.updateAllowNext();
  }

  updateAllowNext() {
    if (this.data[this.type][this.index]) {
      // when it is a slider or when it is already selected -> allowNext is true
      this.directiveRef.swiper().allowSlideNext = this.data[this.type][this.index].isSlider || this.data[this.type][this.index].selected ? true : false;
      this.allowNext = this.data[this.type][this.index].isSlider || this.data[this.type][this.index].selected ? true : false;

      if (this.data[this.type][this.index].onlyWhenSelected) {
        setTimeout(() => {
          console.log(this.index);
        }, 100)
      }
    }
  }

  submitObjectAddress(objectAddressForm: NgForm) {
    if (!objectAddressForm.valid || !this.isZipCodeValid) {
      return;
    }
    this.selectionService.updateSelectionData(this.data);
    this.selectionService.updateObjectAddress(objectAddressForm.value);
    this.selectionService.updateObjectNotes(this.objectNotes);
    this.showLoadingSpinner = true;
    setTimeout(() => {
      this.showLoadingSpinner = false;
      this.router.navigate([this.type, 'kontakt-informationen'])
    }, 3500)
  }
}
