import { Component, OnInit } from '@angular/core';
import { SelectionService } from '../selection/selection.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html'
})
export class ConfirmationComponent implements OnInit {

  type:string;

  constructor(private selectionService:SelectionService, private router:Router, private route:ActivatedRoute) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if(params){
        const type = params['type'];
        if(type == 'grundstueck' || type == 'haus' || type == 'wohnung' || type == 'gewerbe') {
          this.type = type;
        } else {
          this.router.navigate(['/']);
        }
      }
    })

    // if(!this.selectionService.isSelectionDataValid(this.type) || !this.selectionService.objectAddressSnapshot.address || !this.selectionService.contactInformationSnapshot.firstname){
    //   this.router.navigate(['/']);
    // }
  }

}
