import { ActivatedRoute } from '@angular/router';
import { SelectionService } from './../selection/selection.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html'
})
export class NavbarComponent implements OnInit {

  type:string;
  typeTitle:string;
  isContactInformationPage:boolean = false;
  isConfirmationPage:boolean = false;
  isHomePage:boolean = false;

  constructor(private selectionService:SelectionService, private route:ActivatedRoute) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if(params){
        const type = params['type'];
        if(type == 'grundstueck' || type == 'haus' || type == 'wohnung' || type == 'gewerbe') {
          this.type = type;
          this.typeTitle = this.selectionService.getTitleForType(this.type);
        }
      }
    })
    this.route.url.subscribe(urls => {
      if(urls.length == 0){
        this.isHomePage = true;
        return;
      } else if (urls.length == 1){
        return;
      }

      if(urls[1].path == 'kontakt-informationen'){
        this.isContactInformationPage = true;
      } else if (urls[1].path == 'bestaetigung'){
        this.isConfirmationPage = true;
      }
    })
  }

}
