export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyAA77uGiUj5d_bGQFQ9xRjmh8SD8URLE1w",
    authDomain: "remax-immobilienrechner.firebaseapp.com",
    databaseURL: "https://remax-immobilienrechner.firebaseio.com",
    projectId: "remax-immobilienrechner",
    storageBucket: "remax-immobilienrechner.appspot.com",
    messagingSenderId: "334606931246",
    appId: "1:334606931246:web:44868999ae1cc6b0"
  }
};
